<template>
  <el-form style="text-align: right;position: relative;z-index: 99;right: 2vw;margin-bottom: 10px;">
    <el-button type="primary" @click="submit">保存</el-button>
    <el-button @click="back">取消</el-button>
  </el-form>
  <el-tabs type="border-card" v-model="tabName">
    <el-tab-pane name="workform">
      <template #label>
        <span> 流程信息 </span>
      </template>

      <el-form :model="forms" :rules="rules" ref="forms" label-width="110px"
        style="max-width: 720px;margin: 0px auto;border: 1px solid #f2f2f2;padding: 20px 10px;">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="流程名称" prop="flowName">
              <el-input v-model="forms.flowName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="流程编号" prop="flowCode">
              <el-input v-model="forms.flowCode" autocomplete="off" style="max-width: 250px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="是否启用" prop="enabled">
              <el-radio v-model="forms.enabled" :label="true"> 启用 </el-radio>
              <el-radio v-model="forms.enabled" :label="false"> 禁用 </el-radio>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="单据编号前缀" prop="prefixCode">
              <el-input v-model="forms.prefixCode" autocomplete="off" style="max-width: 150px" maxlength="6" placeholder="" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="排序号" prop="sortCode">
              <el-input-number v-model="forms.sortCode" :min="1" :max="9999999" label="排序号"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="备注">
              <el-input v-model="forms.descriptions" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-tab-pane>
    <el-tab-pane name="workflow">
      <template #label>
        <span> 流程图配置 </span>
      </template>

      <div id="flowapp">
        <drawapp ref="drawapp1" :data="defaultData" :height="500" :width="800" :lang="lang" />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { submitConfiger, fetchConfigerDetail } from "@/api/workflow";
import drawapp from "@/components/flowDraw";
export default {
  name: "app",
  components: { drawapp, },
  data() {
    return {
      tabName: "workform",
      lang: "zh",
      defaultData: {
        nodes: [
          { type: "start-node", id: "startNode1", x: 50, y: 200, label: "", clazz: "start", style: {}, size: [30, 30], },
          { type: "user-task-node", id: "taskNode1", x: 200, y: 200, label: "上级审批", clazz: "userTask", style: {}, size: [80, 44], },
          { type: "script-task-node", id: "taskNode2", x: 400, y: 200, label: "经理审批", clazz: "scriptTask", style: {}, size: [80, 44], },
          { clazz: "end", size: [30, 30], label: "", type: "end-node", shape: "end-node", x: 580, y: 200, id: "end1638431832073", style: {}, },
        ],
        edges: [
          {
            source: "startNode1", target: "taskNode1", sourceAnchor: 1, targetAnchor: 3, clazz: "flow",
            type: "flow-polyline-round", id: "edge1", style: {},
            startPoint: { x: 65.5, y: 200, index: 1, },
            endPoint: { x: 160, y: 200, index: 3, },
          },
          {
            source: "taskNode1", target: "taskNode2", sourceAnchor: 1, targetAnchor: 3, clazz: "flow",
            type: "flow-polyline-round", id: "edge4", style: {},
            startPoint: { x: 240.5, y: 200, index: 1, },
            endPoint: { x: 359.5, y: 200, index: 3, },
          },
          {
            id: "flow1638431834918", clazz: "flow", source: "taskNode2", target: "end1638431832073",
            sourceAnchor: 1, targetAnchor: 2, type: "flow-polyline-round", style: {},
            startPoint: { x: 440.5, y: 200, index: 1, },
            endPoint: { x: 565, y: 200, index: 2, },
          },
        ],
      },
      forms: {
        enabled: true,
        sortCode: 1,
        flowData: {},
      },
      rules: {
        flowName: [
          { required: true, message: "请输入流程名称", trigger: "blur" },
        ],
        flowCode: [
          { required: true, message: "请输入流程编号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    submit() {
      let jsonData = this.$refs["drawapp1"].graph.save();
      console.log(jsonData);

      if ((this.forms.flowName || '') == '' || (this.forms.flowCode || '') == '') {
        this.$message({
          type: "error",
          message: '请补充输入流程信息',
        });
        return;
      }

      let that = this;
      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        this.forms.flowData = JSON.stringify(jsonData);
        submitConfiger(this.forms).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });

            that.back();
          }
        });
      });
    },
  },
  mounted() {
    let that = this;
    that.id = this.$route.query.id;
    if (that.id && that.id.length > 0) {
      fetchConfigerDetail(that.id).then((res) => {
        that.forms = res.data;
        that.defaultData = JSON.parse(res.data.flowData);
      });
    }
  },
};
</script>

<style>
#flowapp {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
